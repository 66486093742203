import "./article.css";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Box, Grid, Link, Typography} from "@mui/material";
import {useEffect, useState} from "react";

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: ['Lato', 'sans-serif'].join(',')
        },
    },
});

function Video() {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);
    if (width > 1450) {
        return (
            <iframe src="https://drive.google.com/file/d/1kf8OT96m4dIJFb3Pg5HysTtqJ8uwMe9X/preview" width="1280" height="720" allow="autoplay"></iframe>
        );
    }
    else if (width > 650) {
        return (
            <iframe src="https://drive.google.com/file/d/1kf8OT96m4dIJFb3Pg5HysTtqJ8uwMe9X/preview" width="640" height="360" allow="autoplay"></iframe>
        )
    }
    return (
        <div>
            <iframe src="https://drive.google.com/file/d/1kf8OT96m4dIJFb3Pg5HysTtqJ8uwMe9X/preview" width="426.667" height="240" allow="autoplay"></iframe>
        </div>
    );
}

function ProceduralPlanetarySystem() {
    return (
        <ThemeProvider theme={theme}>
            <Box marginX={{xs:5, md:10, lg:20}} marginBottom={5}>
                <Box
                    component="img"
                    src="https://images.unsplash.com/photo-1462332420958-a05d1e002413?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=4207&q=80"
                    className={'coverImage'}
                    marginBottom={3}
                />
                <Grid container rowSpacing={4}>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h3"
                            fontWeight="bold"
                            gutterBottom
                        >
                            Procedurally Generated Planetary System
                        </Typography>
                        <Typography
                            varient="subtitle1"
                            fontWeight="bold"
                            className={'breadcrumb'}
                        >
                            Dec 2022 / CSCI 2230 Computer Graphics
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Grid container columns={16} className={'context'}>
                            <Grid item xs={1} className={'largeEmoji'}>📚</Grid>
                            <Grid item xs={14}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="Bold"
                                    paragraph
                                >
                                    Context
                                </Typography>
                                <Typography paragraph>
                                    This is a final project of the Computer Graphics course. We made a procedurally generated planetary system with OpenGL, where each planet revolves around the sun and rotates on its own axis.
                                    The user can control the camera to focus on one single planet, and the planets' surfaces are texture-mapped using images or procedurally generated textures. Normal mapping is also implemented to create terrain-like effects.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Typography fontWeight="bold">
                            <ul className={'tableOfContents'}>
                                <li>
                                    <Link href={'#part1'}>1 - Orbits and Movements</Link>
                                </li>
                                <li>
                                    <Link href={'#part2'}>2 - Planetary System Generation</Link>
                                </li>
                                <li>
                                    <Link href={'#part3'}>3 - Camera</Link>
                                </li>
                                <li>
                                    <Link href={'#part4'}>4 - Texture Generation</Link>
                                </li>
                                <li>
                                    <Link href={'#part5'}>5 - Normal Mapping</Link>
                                </li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part1"
                            gutterBottom
                        >
                            Project Demo Video
                        </Typography>
                        <Box display='flex' justifyContent='center'>
                            <Video />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part1"
                            gutterBottom
                        >
                            1 - Orbits and Movements
                        </Typography>
                        <Typography paragraph>
                            A planet orbits around another with respect to an orbital axis and radius.
                            Planets are stored in a tree such that updating each planet’s CTM is equivalent to performing a pre-order traversal.
                            This hierarchical structure enables nested rotational relationships (moons orbiting around planets, systems orbiting around systems, etc.)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part2"
                            gutterBottom
                        >
                            2 - Planetary System Generation
                        </Typography>
                        <Typography paragraph>
                            The user could generate a procedural planetary system that is similar to the default solar system with some control over the parameters.
                            These planets use procedurally generated textures for further variations.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part3"
                            gutterBottom
                        >
                            3 - Camera
                        </Typography>
                        <Typography paragraph>
                            There are two camera modes:
                            <ul>
                                <li>
                                    Default Camera: Use <strong>W, A, S, D</strong> to move and the <strong>mouse</strong> to look around.
                                </li>
                                <li>
                                    Orbit Camera: Use <strong>W, S</strong> to zoom in and out; use <strong>A, D</strong> to switch between planets; use the <strong>mouse</strong> to rotate about the planet.
                                </li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part4"
                            gutterBottom
                        >
                            4 - Texture Generation
                        </Typography>
                        <Typography paragraph>
                            The planets’ surfaces are texture-mapped using images, but we also implemented a noise-generated terrain, which makes the planets have cartoon-like styles.
                            The planet colors are random variations on some pre-defined color palettes. We divide planets into two types - one with “terrain” and the other with “rings”.
                            For terrain-like texture generation, we implemented Perlin noise, and used the noise values to get the colors. For planets with rings, we used a simple Beizer Curve.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part5"
                            gutterBottom
                        >
                            5 - Normal Mapping
                        </Typography>
                        <Typography paragraph>
                            Normals are sampled from image textures, which stores values in tangent space.
                            To make lighting works correctly, we transform the lighting variables from world space to tangent space, and calculate lighting with tangent-space normals.
                            This transformation is done in the vertex shader, since lighting variables remain the same across all fragments.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    )
}

export default ProceduralPlanetarySystem;