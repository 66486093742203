import { ThemeProvider, createTheme } from '@mui/material/styles';
import {Box, Container, Grid, Link, Typography} from "@mui/material";

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: ['Lato', 'sans-serif'].join(',')
        },
    },
});

function Home() {
    return (
        <ThemeProvider theme={theme}>
            <Box margin={{xs: 2, md: 10}}>
                <Grid container rowSpacing={10}>
                    <Grid item xs={12} marginBottom={{xs: "2%", md: "3%"}}>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./memoji.png"}
                            alt="memoji"
                            sx={{
                                width: "30vh",
                                marginBottom: 8
                            }}
                        />
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            fontWeight="bold"
                            gutterBottom
                        >
                            Qian (Keven) Li
                        </Typography>
                        <Typography variant="h5" align="center" paragraph>
                            👨🏻‍💻 A Computer Science student currently at Brown University. 🐻
                        </Typography>
                        <Typography variant="h5" align="center" paragraph>
                            🗺️ Loves to travel, have a passion for photography, and always enjoy coding. 🛫
                        </Typography>
                        <Typography variant="h5" align="center" paragraph>
                            🚧 Under construction. This version is for the CSCI 1300 UI/UX course. 🚧
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} paddingX={{xs: "2%", md: "5%"}} alignItems="center">
                        <Grid item xs={12} md={5}>
                            <Typography
                                component="h2"
                                variant="h3"
                                color="text.primary"
                                fontWeight="bold"
                                gutterBottom
                                align="center"
                            >
                                <Link href={'/personas'} underline="none" color="inherit">Personas & Storyboarding</Link>
                            </Typography>
                            <Typography variant="h6" paragraph align="center">
                                This case study aimed to create personas and storyboards through observation and interviews to understand users' perspectives of a microwave oven interface.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Container
                                component="img"
                                src={process.env.PUBLIC_URL +"./assets/personas_and_storyboarding/Digital_Storyboard.png"}
                                alt="Storyboard"
                                sx={{
                                    width: "85%"
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} paddingX={{xs: "2%", md: "5%"}} alignItems="center">
                        <Grid item xs={12} md={7}>
                            <Container
                                component="img"
                                src={process.env.PUBLIC_URL +"./assets/responsive_redesign/mockup.png"}
                                alt="Mockups"
                                sx={{
                                    width: "85%"
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Typography
                                component="h2"
                                variant="h3"
                                color="text.primary"
                                fontWeight="bold"
                                gutterBottom
                                align="center"
                            >
                                <Link href={'/responsive'} underline="none" color="inherit">Responsive Redesign</Link>
                            </Typography>
                            <Typography variant="h6" paragraph align="center">
                                This project involved analyzing the flaws of an existing webpage, creating low-fidelity and high-fidelity prototypes, and building a responsive website using Bootstrap.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} paddingX={{xs: "2%", md: "5%"}} alignItems="center">
                        <Grid item xs={12} md={5}>
                            <Typography
                                component="h2"
                                variant="h3"
                                align="center"
                                color="text.primary"
                                fontWeight="bold"
                                gutterBottom
                            >
                                <Link href={'/iterative'} underline="none" color="inherit">Iterative Design</Link>
                            </Typography>
                            <Typography variant="h6" align="center" paragraph>
                                We created an effective and user-friendly iOS banking app for truck drivers through an iterative design process that includes sketching, wireframing, prototype review, and user testing.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Container
                                component="img"
                                src={process.env.PUBLIC_URL +"./assets/iterative_design/sketch_1.png"}
                                alt="Sketches"
                                sx={{
                                    width: "85%"
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} paddingX={{xs: "2%", md: "5%"}} alignItems="center">
                        <Grid item xs={12} md={7}>
                            <Container
                                component="img"
                                src={process.env.PUBLIC_URL +"./assets/procedurally_generated_planetary_system/screenshot.png"}
                                alt="Screenshot"
                                sx={{
                                    width: "85%"
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Typography
                                component="h2"
                                variant="h3"
                                align="center"
                                color="text.primary"
                                fontWeight="bold"
                                gutterBottom
                            >
                                <Link href={'/procedural'} underline="none" color="inherit">Procedurally Generated Planetary System</Link>
                            </Typography>
                            <Typography variant="h6" align="center" paragraph>
                                A procedurally generated planetary system made with OpenGL, featuring orbit camera, procedurally generated planetary system, texture-mapped surfaces, and normal mapping for terrain-like effects.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

        </ThemeProvider>
    );
}

export default Home;