import "./article.css";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Box, Container, Grid, Link, Typography} from "@mui/material";

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: ['Lato', 'sans-serif'].join(',')
        },
    },
});

function PersonasAndStoryboarding() {
    return (
        <ThemeProvider theme={theme}>
            <Box marginX={{xs:5, md:10, lg:20}} marginBottom={5}>
                <Box
                    component="img"
                    src="https://images.unsplash.com/photo-1522542550221-31fd19575a2d?ixlib=rb-1.2.1&q=80&cs=tinysrgb&fm=jpg&crop=entropy"
                    className={'coverImage'}
                    marginBottom={3}
                />
                <Grid container rowSpacing={4}>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h3"
                            fontWeight="bold"
                            gutterBottom
                        >
                            Personas & Storyboarding
                        </Typography>
                        <Typography
                            varient="subtitle1"
                            fontWeight="bold"
                            className={'breadcrumb'}
                        >
                            Sept 2022 / CSCI 1300 User Interface and User Experience
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Grid container columns={16} className={'context'}>
                            <Grid item xs={1} className={'largeEmoji'}>📚</Grid>
                            <Grid item xs={14}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="Bold"
                                    paragraph
                                >
                                    Context
                                </Typography>
                                <Typography paragraph>
                                    This case study is an assignment of the UI/UX course.
                                    Its goal is to create personas and storyboards through observations and interviews,
                                    trying to understand the situation from users’ prospective.
                                </Typography>
                                <Typography paragraph>
                                    I chose the interface of a microwave oven for this study.
                                    I observed multiple users interacting with the microwave,
                                    and designed some questions to interview users about their experiences.
                                    From the observation and feedbacks,
                                    I created 2 personas to represent different types of users,
                                    and used one of the persona to create a storyboard showcasing a user’s journey.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Typography fontWeight="bold">
                            <ul className={'tableOfContents'}>
                                <li><Link href={'#part1'}>1 - Preparation</Link></li>
                                <li>
                                    <Link href={'#part2'}>2 - Recording Observations</Link>
                                    <li>
                                        <Link href={'#part2-1'}>💡 Key Observations</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part2-2'}>📝 Questions and Responses</Link>
                                    </li>
                                </li>
                                <li>
                                    <Link href={'#part3'}>3 - Personas</Link>
                                    <li>
                                        <Link href={'#part3-1'}>🙋🏻‍♂️ Steven</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part3-2'}>💁🏼‍♀️ Janet</Link>
                                    </li>
                                </li>
                                <li>
                                    <Link href={'#part4'}>4 - Storyboard</Link>
                                    </li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part1"
                        >
                            1 - Preparation
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography className={'lines'}>
                            The control panel of this microwave oven is located on the right side, at the front of the appliance.
                        </Typography>
                        <Typography className={'lines'}>
                            In the center, you'll find cooking presets for specific types of food.
                        </Typography>
                        <Typography className={'lines'}>
                            It has function keys at the top, which you can use in combination with the number buttons below.
                        </Typography>
                        <Typography className={'lines'}>
                            At the bottom of the panel, there are stop/start keys and the number keys for inputting cook times or using express cooking options.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/personas_and_storyboarding/microwave_interface.png"}
                            sx={{
                                width: "40%"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part2"
                        >
                            2 - Recording Observations
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            id="part2-1"
                        >
                            💡 Key Observations
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            <ul>
                                <li>
                                    Most of the users will only press the express cook buttons.
                                </li>
                                <li>
                                    Those using the defrost function are unlikely to succeed in their first attempt.
                                </li>
                                <li>
                                    No one use cooking presets for their food.
                                </li>
                                <li>
                                    <em>Time Cook</em> and <em>Time Defrost</em> are the two most-used buttons in function keys.
                                </li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            paragraph
                            id="part2-2"
                        >
                            📝 Questions and Responses
                        </Typography>
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                        >
                            Questions
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            <ol>
                                <li>
                                    What do you usually use the microwave for?
                                </li>
                                <li>
                                    What do you think each of these buttons does?
                                </li>
                                <li>
                                    Have you experienced any difficulties or confusion when using this microwave?
                                </li>
                                <li>
                                    What buttons do you use most for controlling the microwave?
                                </li>
                            </ol>
                        </Typography>
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                        >
                            Summarized Responses
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            <ul>
                                <li>
                                    People usually use the microwave for reheating, defrosting, and some simple cooking.
                                </li>
                                <li>
                                    People generally don’t have much understanding of different functions and settings on the panel and tend to avoid them when operating microwaves.
                                </li>
                                <li>
                                    People expressed confusion for the function keys and cooking presets, stating that there will be numbers with unclear meaning showing on the screen after they press the button, or they have to input some numbers, but they don’t know what those numbers stand for.
                                </li>
                                <li>
                                    People expressed their dissatisfaction with the interface and they tend to use the most simple buttons - <em>Express Cook</em>, <em>Time Cook</em> and <em>Start / Stop</em>.
                                </li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part3"
                        >
                            3 - Personas
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part3-1"
                        >
                            🙋🏻‍♂️ Steven
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            <ul>
                                <li style={{fontWeight: "bold"}}>
                                    Steven is a white-collar worker who recently started to bring his own lunch to the office.
                                    It’s lunchtime and he’s in the office kitchen to heat up his lunch.
                                </li>
                                <li>
                                    He’s not familiar with this microwave and tries to figure out which function he should use.
                                </li>
                                <li>
                                    This persona represents users who are not familiar with or new to the interface and depicts the first few times of their interaction with the microwave.
                                </li>
                            </ul>
                        </Typography>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/personas_and_storyboarding/Empathy_Map_(1).png"}
                            sx={{
                                width: "100%"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part3-2"
                        >
                            💁🏼‍♀️ Janet
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            <ul>
                                <li style={{fontWeight: "bold"}}>
                                    Janet is a cooking lover. She is hosting a dinner party and she uses a microwave oven to help her prepare the food.
                                </li>
                                <li>
                                    She knows the basics of using this microwave but still finds it nonintuitive from time to time.
                                </li>
                                <li>
                                    This persona represents users who are familiar with cooking but still find it hard / trying to figure out the advanced functions of the microwave oven.
                                </li>
                            </ul>
                        </Typography>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/personas_and_storyboarding/Empathy_Map_(2).png"}
                            sx={{
                                width: "100%"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part4"
                        >
                            4 - Storyboard
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/personas_and_storyboarding/Digital_Storyboard.png"}
                            alt="Storyboard Image"
                            sx={{
                                width: "100%"
                            }}
                        />
                        <Typography variant="body1">
                            <ol>
                                <li>
                                    Steven is new here. Today he brings his lunch to the office and needs to use the microwave oven to heat it up.
                                </li>
                                <li>
                                    He closely examines the control panel on the microwave, but he’s not sure which function to use.
                                </li>
                                <li>
                                    He finds the power button and tries to press it, but nothing happens.
                                </li>
                                <li>
                                    Steven wishes to figure out what’s the proper way to set the power, but seeing the long line waiting for the microwave after him, he chooses to use the Express Cook 2 button.
                                </li>
                                <li>
                                    Worrying his food might be overheated, Steven pauses the microwave several times and to check the food’s temperature.
                                </li>
                                <li>
                                    When there are still 30 seconds left, Steven thinks his lunch is hot enough. So he presses the stop button and takes his lunch out.
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    )
}

export default PersonasAndStoryboarding;