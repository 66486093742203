import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import PersonasAndStoryboarding from "./pages/PersonasAndStoryboarding";
import ResponsiveRedesign from "./pages/ResponsiveRedesign";
import IterativeDesign from "./pages/IterativeDesign";
import ProceduralPlanetarySystem from "./pages/ProceduralPlanetarySystem";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path='/personas' element={<PersonasAndStoryboarding />} />
                    <Route path='/responsive' element={<ResponsiveRedesign />} />
                    <Route path='/iterative' element={<IterativeDesign />} />
                    <Route path='/procedural' element={<ProceduralPlanetarySystem />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}