import "./article.css";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {
    Box,
    Container,
    Grid,
    Link,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: ['Lato', 'sans-serif'].join(',')
        },
    },
});

function IterativeDesign() {
    return (
        <ThemeProvider theme={theme}>
            <Box marginX={{xs:5, md:10, lg:20}} marginBottom={5}>
                <Box
                    component="img"
                    src="https://images.unsplash.com/photo-1587440871875-191322ee64b0?ixlib=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb"
                    className={'coverImage'}
                    marginBottom={3}
                />
                <Grid container rowSpacing={4}>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h3"
                            fontWeight="bold"
                            gutterBottom
                        >
                            Iterative Design
                        </Typography>
                        <Typography
                            varient="subtitle1"
                            fontWeight="bold"
                            className={'breadcrumb'}
                        >
                            Nov 2022 / CSCI 1300 User Interface and User Experience
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Grid container columns={16} className={'context'}>
                            <Grid item xs={1} className={'largeEmoji'}>📚</Grid>
                            <Grid item xs={14}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="Bold"
                                    paragraph
                                >
                                    Context
                                </Typography>
                                <Typography paragraph>
                                    Iterative design is a widely-used approach in the field of UI/UX design.
                                    It involves constantly refining and improving upon a design through repeated cycles of testing and feedback.
                                    This approach allows designers to quickly and efficiently identify and address potential issues with a product, ultimately leading to a more user-friendly and effective end result.
                                </Typography>
                                <Typography paragraph>
                                    In this project, we're going to help Pivo, a startup company, design an iOS app that provides banking services to truck drivers.
                                    We will follow an iterative design process, which includes sketching and wireframing, creating a high-fidelity prototype, undergoing peer review, and conducting user testing.
                                    This approach will allow us to quickly identify and address potential issues with the app, ultimately resulting in a more effective and user-friendly product.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Typography fontWeight="bold">
                            <ul className={'tableOfContents'}>
                                <li>
                                    <Link href={'#part1'}>1 - The Chosen Startup</Link>
                                </li>
                                <li>
                                    <Link href={'#part2'}>2 - Sketching & Wireframing</Link>
                                    <li>
                                        <Link href={'#part2-1'}>✍️ Brainstorming & Sketching</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part2-2'}>🪄 Combining Ideas into a Wireframe</Link>
                                    </li>
                                </li>
                                <li>
                                    <Link href={'#part3'}>3 - Interactive High Fidelity Mockup</Link>
                                    <li>
                                        <Link href={'#part3-1'}>🏠 Home Page</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part3-2'}>🧾 Invoices Page</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part3-3'}>💡 Insights Page</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part3-4'}>📱 The Final Version of Our Mockup</Link>
                                    </li>
                                </li>
                                <li>
                                    <Link href={'#part4'}>4 - User Testing</Link>
                                    <li>
                                        <Link href={'#part4-1'}>🧭 Test Instructions</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part4-2'}>📝 Post-test Questions</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part4-3'}>🧮 Analysis</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part4-4'}>💭 Summary</Link>
                                    </li>
                                </li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part1"
                        >
                            1 - The Chosen Startup
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            id="part1-1"
                            paragraph
                            gutterBottom
                        >
                            💵 Pivo - A Supply Chain Financing Platform
                        </Typography>
                        <Typography paragraph>
                            With the high economic growth in Africa, workers in the freight carrier industry enjoy its benefit while <strong>suffering from the long turnaround time for payment,
                            which leads to severe cash flow problems</strong>. Pivo proposes a solution to this problem by designing a platform that allows freight carriers to collect,
                            make and track payments in one place. The application now focuses on serving <strong>truck drivers and owners</strong> and provides bank account, debit card,
                            and digital invoicing tools so they can better manage their finance.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'} alignSelf={'center'}>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/iterative_design/pivo.png"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part2"
                        >
                            2 - Sketching & Wireframing
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            id="part2-1"
                            gutterBottom
                        >
                            ✍️ Brainstorming & Sketching
                        </Typography>
                        <Typography paragraph>
                            Some text providing more context to be written...
                        </Typography>
                        <Grid container>
                            <Grid item xs={6}>
                                <Container
                                    component="img"
                                    src={process.env.PUBLIC_URL +"./assets/iterative_design/sketch_1.png"}
                                    style={{padding: 0}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Container
                                    component="img"
                                    src={process.env.PUBLIC_URL +"./assets/iterative_design/sketch_2.png"}
                                    style={{padding: 0}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Container
                                    component="img"
                                    src={process.env.PUBLIC_URL +"./assets/iterative_design/sketch_3.png"}
                                    style={{padding: 0}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Container
                                    component="img"
                                    src={process.env.PUBLIC_URL +"./assets/iterative_design/sketch_4.jpeg"}
                                    style={{padding: 0}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            id="part2-2"
                            gutterBottom
                        >
                            🪄 Combining Ideas into a Wireframe
                        </Typography>
                        <Typography paragraph>
                            After finishing the drafting and discussing our solutions, we combined our idea and made the following wireframe, which includes four pages of the interface:
                        </Typography>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/iterative_design/wireframe.png"}
                            style={{padding: 0}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part3"
                        >
                            3 - Interactive High Fidelity Mockup
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography paragraph>
                            To gain more feedback for our proposed framework, we built a hi-fi mockup based on our low-fi prototype,
                            presented our mockup to other fellow UIUX designers in a studio, and got the following critics.
                        </Typography>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part3-1"
                        >
                            🏠 Home Page
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            <ul>
                                <li>
                                    Change the color of the home page button (the blue button and the blue card can give people the impression that they are correlated).
                                </li>
                                <li>
                                    For better security, only keep the last four digits visible on the card.
                                </li>
                                <li>
                                    For the transaction details page of a card, the date 06 Oct 2020 needs to be clarified.
                                </li>
                            </ul>
                        </Typography>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part3-2"
                        >
                            🧾 Invoices Page
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            <ul>
                                <li>
                                    Change the total amount of transactions into percentage transacted.
                                </li>
                                <li>
                                    Red for expenditure and green for income to enhance the visual cue.
                                </li>
                                <li>
                                    Transaction date can be deleted for a pending order.
                                </li>
                            </ul>
                        </Typography>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part3-3"
                        >
                            💡 Insights Page
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            <ul>
                                <li>
                                    Add an exit button for the details page of insights.
                                </li>
                                <li>
                                    Change the color of the text from purple to something else to differentiate it from the purple background on the detailed insights page.
                                </li>
                            </ul>
                        </Typography>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part3-4"
                        >
                            📱 The Final Version of Our Mockup
                        </Typography>
                        <Typography paragraph>
                            We made some refinements to our mockup incorporating the feedbacks. On the right (or below if you're viewing the page from a mobile device) is our final version.
                        </Typography>
                        <Typography paragraph>
                            You can interact with our final mockup directly in the embedded frame.
                            If you did not see the figma model, you can also click <Link href="https://www.figma.com/proto/tjSFu8N1P7QWrytP41laFO" target="_blank">here</Link> to open it in a new tab.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Container>
                            <iframe width="90%" height="1000"
                                    src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FtjSFu8N1P7QWrytP41laFO%2FIterative-Design%253A-Pivo%3Fnode-id%3D1%253A215%26starting-point-node-id%3D1%253A215%26scaling%3Dscale-down"
                                    allowFullScreen></iframe>
                        </Container>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part4"
                        >
                            4 - User Testing
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography paragraph>
                            After editing the high-fi prototype, we used UserTesting.com to conduct online user testing.
                            Three users were asked to complete a series of tasks in our app given the following instructions.
                        </Typography>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            paragraph
                            id="part4-1"
                        >
                            🧭 Test Instructions
                        </Typography>
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            gutterBottom
                        >
                            Context
                        </Typography>
                        <Typography paragraph>
                            The prototype you’ll be testing is a smart-banking app for those who receive multiple one-time payments from different sources.
                            The app helps users track all their invoices in one place and get insights into their income.
                        </Typography>
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            gutterBottom
                        >
                            Notes
                        </Typography>
                        <Typography paragraph>
                            Thank you for taking this test! The interface you’ll be testing is NOT an actual app but an interactive mockup made on Figma.
                            Some elements on the page are not interactive. When you click on those areas, there will be blue boxes indicating where you can click.
                            You can interact with the mockup using your mouse as your finger.
                        </Typography>
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            gutterBottom
                        >
                            Tasks
                        </Typography>
                        <Typography paragraph>
                            Assume you are a truck driver who delivers goods for multiple companies and gets paid for each order you complete.
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            gutterBottom
                        >
                            A. Track Invoices
                        </Typography>
                        <Box sx={{paddingLeft: 2}}>
                            <Typography paragraph>
                                The turnover period of transactions can be quite long. You have completed multiple orders as a truck driver, but some of your payments are long overdue.
                            </Typography>
                            <Typography variant="body1">
                                <ol>
                                    <li>
                                        Try to contact (find the contact button) the company that has an overdue payment in October.
                                    </li>
                                    <li>
                                        Filter out all the overdue invoices.
                                    </li>
                                </ol>
                            </Typography>
                        </Box>
                        <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            gutterBottom
                        >
                            B. Gain Financial Insights
                        </Typography>
                        <Box sx={{paddingLeft: 2}}>
                            <Typography paragraph>
                                After receiving the payment, you are curious about the changes in your financial situation throughout the year.
                                Luckily, our app provides data analytics that tracks such changes. Head to the app and find the following insights:
                            </Typography>
                            <Typography variant="body1">
                                <ol>
                                    <li>
                                        Find the month from January to June with the lowest income.
                                    </li>
                                    <li>
                                        Find out which company paid you the most in May.
                                    </li>
                                </ol>
                            </Typography>
                        </Box>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            paragraph
                            id="part4-2"
                        >
                            📝 Post-test Questions
                        </Typography>
                        <Typography variant="body1">
                            <ol>
                                <li>
                                    What frustrated you most about this app?
                                </li>
                                <li>
                                    If you had a magic wand, how would you improve the experience for the tasks you completed?
                                </li>
                                <li>
                                    What did you like about this app?
                                </li>
                            </ol>
                        </Typography>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part4-3"
                        >
                            🧮 Analysis
                        </Typography>
                        <Typography paragraph>
                            While the users are reacting to the interface and performing the task,
                            their reactions and responses are recorded in a video and we synthesize them as the table on the right (below if you're viewing on a mobile device).
                        </Typography>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            paragraph
                            id="part4-4"
                        >
                            💭 Summary
                        </Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>
                                    The overall responses we received from the users regarding our interface are positive.
                                    Users were able to finish most of the assigned tasks with relative ease.
                                </li>
                                <li>
                                    Some difficulties and subsequent errors stem from the UserTesting/Figma websites failing to display the complete testing interface.
                                    Next time we conduct similar testing, we will <strong>add more instructions</strong> at the start of the test to <strong>ensure users can see the entire prototype</strong>.
                                </li>
                                <li>
                                    The most prominent issue we discovered about the interface was the <strong>word choice in the Insights tab</strong>.
                                    All three users complained about ambiguity in the term “last month” in statements like “Company X paid you the most last month”: they were unsure whether the term referred to the currently highlighted month or the month prior to it.
                                    Our proposed solution is to <strong>use the actual name of the highlighted month</strong> rather than “last month” to avoid any ambiguity.
                                </li>
                                <li>
                                    User 2 manually spotted all overdue invoices rather than using the filter.
                                    This suggested a plausible learnability issue in our pull-down filter menu.
                                    A potential solution is to be more explicit in the filter options, e.g., <strong>replacing “All” with “Filter: All”</strong>.
                                </li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <TableContainer>
                            <Table className={'table'} style={{margin: 0}}>
                                <TableHead style={{verticalAlign: 'top', textAlign: 'center'}}>
                                    <TableRow>
                                        <TableCell scope={'col'} colSpan={2} style={{textAlign: 'left', verticalAlign: 'middle', minWidth: 200}}>
                                            <Typography variant="subtitle1" fontWeight="bold">Task \ User</Typography>
                                        </TableCell>
                                        <TableCell scope={"col"}>
                                            <Link
                                                href="https://app.usertesting.com/v/59bfe6c3-6b38-4ca1-9c09-11d7ce8ab0c5?encrypted_video_handle=d67a5197-06e4-4afa-b6b4-a99e95e0636a#!/notes&shared_via=link"
                                                target="_blank"
                                            >
                                                <Box
                                                    component="img"
                                                    src={process.env.PUBLIC_URL +"./assets/iterative_design/user1.gif"}
                                                    style={{height: '4rem', margin: 'auto'}}
                                                />
                                            </Link>
                                            <Typography variant="subtitle1" fontWeight="bold">alexo</Typography>
                                            <Typography variant="subtitle2">52, Male<br/>(Australia)</Typography>
                                        </TableCell>
                                        <TableCell scope={"col"}>
                                            <Link
                                                href="https://app.usertesting.com/v/59bfe6c3-6b38-4ca1-9c09-11d7ce8ab0c5?encrypted_video_handle=d67a5197-06e4-4afa-b6b4-a99e95e0636a#!/notes&shared_via=link"
                                                target="_blank"
                                            >
                                                <Box
                                                    component="img"
                                                    src={process.env.PUBLIC_URL +"./assets/iterative_design/user2.gif"}
                                                    style={{height: '4rem', margin: 'auto'}}
                                                />
                                            </Link>
                                            <Typography variant="subtitle1" fontWeight="bold">veronaskylark</Typography>
                                            <Typography variant="subtitle2">33, Female<br/>(United States)</Typography>
                                        </TableCell>
                                        <TableCell scope={"col"}>
                                            <Link
                                                href="https://app.usertesting.com/v/59bfe6c3-6b38-4ca1-9c09-11d7ce8ab0c5?encrypted_video_handle=d67a5197-06e4-4afa-b6b4-a99e95e0636a#!/notes&shared_via=link"
                                                target="_blank"
                                            >
                                                <Box
                                                    component="img"
                                                    src={process.env.PUBLIC_URL +"./assets/iterative_design/user3.gif"}
                                                    style={{height: '4rem', margin: 'auto'}}
                                                />
                                            </Link>
                                            <Typography variant="subtitle1" fontWeight="bold">EducatorPossible</Typography>
                                            <Typography variant="subtitle2">33, Male<br/>(United States)</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{textAlign: 'center'}}>
                                    {/*Task A1*/}
                                    <TableRow>
                                        <TableCell scope={"row"} rowSpan={4} variant={'head'} style={{fontWeight: 'bold'}}>
                                            A1.<br/>Try to contact (find the contact button) the company
                                            that has an overdue payment in October.
                                        </TableCell>
                                        <TableCell>Ease of task</TableCell>
                                        <TableCell>3/5</TableCell>
                                        <TableCell>4/5</TableCell>
                                        <TableCell>4/5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Completion</TableCell>
                                        <TableCell>No</TableCell>
                                        <TableCell>Yes</TableCell>
                                        <TableCell>Yes</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Difficulties</TableCell>
                                        <TableCell
                                            scope={"row"}
                                            colSpan={3}
                                            style={{textAlign: 'left'}}
                                        >
                                            <ul style={{margin: 0}}>
                                                <li>
                                                    Both user 1 and user 3 struggled to find the Invoice
                                                    tab due to Figma display issue, where only part of the
                                                    phone screen was displayed.
                                                </li>
                                                <li>
                                                    User 2 struggled to find the Invoice tab that was
                                                    blocked by a UserTesting popup.
                                                </li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Potential solutions</TableCell>
                                        <TableCell
                                            scope={"row"}
                                            colSpan={3}
                                            style={{textAlign: 'left'}}
                                        >
                                            <ul style={{margin: 0}}>
                                                <li>
                                                    Do more extensive trial runs before publishing the
                                                    user test.
                                                </li>
                                                <li>
                                                    Add instructions to ensure the user can see the entire
                                                    interface before the test begins.
                                                </li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>

                                    {/*Task A2*/}
                                    <TableRow>
                                        <TableCell scope={"row"} rowSpan={4} variant={'head'} style={{fontWeight: 'bold'}}>
                                            A2.<br/>Filter out all the overdue invoices.
                                        </TableCell>
                                        <TableCell>Ease of task</TableCell>
                                        <TableCell>5/5</TableCell>
                                        <TableCell>5/5</TableCell>
                                        <TableCell>5/5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Completion</TableCell>
                                        <TableCell>Yes</TableCell>
                                        <TableCell>Yes</TableCell>
                                        <TableCell>Yes</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Difficulties</TableCell>
                                        <TableCell
                                            scope={"row"}
                                            colSpan={3}
                                            style={{textAlign: 'left'}}
                                        >
                                            <ul style={{margin: 0}}>
                                                <li>
                                                    [Unexpected] User 2 manually spotted all overdue invoices instead of using the filter.
                                                </li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Potential solutions</TableCell>
                                        <TableCell
                                            scope={"row"}
                                            colSpan={3}
                                            style={{textAlign: 'left'}}
                                        >
                                            <ul style={{margin: 0}}>
                                                <li>
                                                    Make filter options more explicit: e.g. use “Filter: All”.
                                                </li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>

                                    {/*Task B1*/}
                                    <TableRow>
                                        <TableCell scope={"row"} rowSpan={4} variant={'head'} style={{fontWeight: 'bold'}}>
                                            B1.<br/>Find the month from January to June with the lowest income.
                                        </TableCell>
                                        <TableCell>Ease of task</TableCell>
                                        <TableCell>5/5</TableCell>
                                        <TableCell>5/5</TableCell>
                                        <TableCell>5/5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Completion</TableCell>
                                        <TableCell>Yes</TableCell>
                                        <TableCell>Yes</TableCell>
                                        <TableCell>Yes</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Difficulties</TableCell>
                                        <TableCell
                                            scope={"row"}
                                            colSpan={3}
                                            style={{textAlign: 'left'}}
                                        >
                                            N/A
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Potential solutions</TableCell>
                                        <TableCell
                                            scope={"row"}
                                            colSpan={3}
                                            style={{textAlign: 'left'}}
                                        >
                                            N/A
                                        </TableCell>
                                    </TableRow>

                                    {/*Task B2*/}
                                    <TableRow>
                                        <TableCell scope={"row"} rowSpan={4} variant={'head'} style={{fontWeight: 'bold'}}>
                                            B2.<br/>Find out which company paid you the most in May.
                                        </TableCell>
                                        <TableCell>Ease of task</TableCell>
                                        <TableCell>5/5</TableCell>
                                        <TableCell>3/5</TableCell>
                                        <TableCell>3/5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Completion</TableCell>
                                        <TableCell>Yes</TableCell>
                                        <TableCell>No (not sure if completed)</TableCell>
                                        <TableCell>No (not sure if completed)</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Difficulties</TableCell>
                                        <TableCell
                                            scope={"row"}
                                            colSpan={3}
                                            style={{textAlign: 'left'}}
                                        >
                                            <ul style={{margin: 0}}>
                                                <li>
                                                    All users were unsure whether “last month” in the Insights tab refers to the month currently highlighted or the previous month.
                                                </li>
                                                <li>
                                                    All users attempted to click on June to find the least-paying company “last month,” i.e., May.
                                                </li>
                                                <li>
                                                    [Unexpected] User 3 entered the Invoice tab rather than Insights to try to manually find the least-paying company.
                                                </li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Potential solutions</TableCell>
                                        <TableCell
                                            scope={"row"}
                                            colSpan={3}
                                            style={{textAlign: 'left'}}
                                        >
                                            <ul style={{margin: 0}}>
                                                <li>
                                                    Change “last month” to the highlighted month.
                                                </li>
                                                <li>
                                                    Provide clearer instruction in future user testing (e.g., “Using Insights tab, find…”)
                                                </li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    )
}

export default IterativeDesign;