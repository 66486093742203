import React from "react";
import {Box, Typography} from "@mui/material"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {createTheme, ThemeProvider} from "@mui/material/styles";

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: ['Lato', 'sans-serif'].join(',')
        },
    },
});

function NavbarHeader() {
    return (
        <ThemeProvider theme={theme}>
            <Box marginX={{xs:0, md:10}} paddingX="4.65%">
                <Navbar className="justify-content-between" collapseOnSelect expand="md">
                    <Navbar.Brand href="/"><Typography variant="h6" fontWeight="bold">Keven's Portfolio</Typography></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                        <Nav className="justify-content-end">
                            <Nav.Link href="/personas"><Typography>Personas & Storyboarding</Typography></Nav.Link>
                            <Nav.Link href="/responsive"><Typography>Responsive Redesign</Typography></Nav.Link>
                            <Nav.Link href="/iterative"><Typography>Iterative Design</Typography></Nav.Link>
                            <Nav.Link href="/procedural"><Typography>Procedurally Generated Planetary System</Typography></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Box>
        </ThemeProvider>
    );
}

export default NavbarHeader;