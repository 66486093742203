import "./article.css";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Box, Container, Grid, Link, Typography} from "@mui/material";

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: ['Lato', 'sans-serif'].join(',')
        },
    },
});

function ResponsiveRedesign() {
    return (
        <ThemeProvider theme={theme}>
            <Box marginX={{xs:5, md:10, lg:20}} marginBottom={5}>
                <Box
                    component="img"
                    src="https://images.unsplash.com/photo-1534670007418-fbb7f6cf32c3?ixlib=rb-4.0.3&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb"
                    className={'coverImage'}
                    marginBottom={3}
                />
                <Grid container rowSpacing={4}>
                    <Grid item xs={12}>
                        <Typography
                            component="h1"
                            variant="h3"
                            fontWeight="bold"
                            gutterBottom
                        >
                            Responsive Redesign
                        </Typography>
                        <Typography
                            varient="subtitle1"
                            fontWeight="bold"
                            className={'breadcrumb'}
                        >
                            Oct 2022 / CSCI 1300 User Interface and User Experience
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Grid container columns={16} className={'context'}>
                            <Grid item xs={1} className={'largeEmoji'}>📚</Grid>
                            <Grid item xs={14}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="Bold"
                                    paragraph
                                >
                                    Context
                                </Typography>
                                <Typography paragraph>
                                    This case study is an assignment of the UI/UX course.
                                    Its goal is to redesign a simple website to improve its usability, and create a responsive web page based on the design.
                                </Typography>
                                <Typography paragraph>
                                    In this project, I analyzed the flaws from an existing webpage I chose,
                                    and created low-fidelity and high-fidelity prototypes, and built a responsive website using Bootstrap based on the prototypes.
                                </Typography>
                                <Typography gutterBottom>
                                    <Link href={'https://www.icbc-us.com/ICBC/海外分行/工银美国网站/EN/ProductsServices/PersonalBanking/Deposit/CheckingAccount/checkingaccount.htm'}
                                          fontWeight="bold"
                                          color="#7cb342"
                                          target="_blank"
                                    >
                                        The Original Webpage
                                    </Link>
                                </Typography>
                                <Typography gutterBottom>
                                    <Link href={'https://cs1300-webpage.vercel.app/responsive_redesign/responsive_redesign.html'}
                                          fontWeight="bold"
                                          color="#fb8c00"
                                          target="_blank"
                                    >
                                        The Redesigned Webpage
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Typography fontWeight="bold">
                            <ul className={'tableOfContents'}>
                                <li>
                                    <Link href={'#part1'}>1 - Identifying Usability Problems</Link>
                                    <li>
                                        <Link href={'#part1-1'}>🌎 Picking a Webpage</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part1-2'}>💡 Finding Problems</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part1-3'}>🦻🏻 Accessibility</Link>
                                    </li>
                                </li>
                                <li>
                                    <Link href={'#part2'}>2 - Visual Redesign</Link>
                                    <li>
                                        <Link href={'#part2-1'}>✍️ Low-Fidelity Wireframing</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part2-2'}>🪄 Visual Design Style Guide</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part2-3'}>🛠 High-Fidelity Prototyping</Link>
                                    </li>
                                </li>
                                <li>
                                    <Link href={'#part3'}>3 - Responsive Redesign</Link>
                                    <li>
                                        <Link href={'#part3-1'}>🔗 The Redesigned Webpage</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part3-2'}>🔎 Header and Navigation Bar</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part3-3'}>🧭 Overview Cards</Link>
                                    </li>
                                    <li>
                                        <Link href={'#part3-4'}>🔦 Detail Cards</Link>
                                    </li>
                                </li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part1"
                        >
                            1 - Identifying Usability Problems
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            id="part1-1"
                            paragraph
                            gutterBottom
                        >
                            🌎 Picking a Webpage
                        </Typography>
                        <Typography paragraph>
                            The Industrial and Commercial Bank of China (ICBC) is a state-owned commercial bank with a global presence in over 40 countries.
                            It is the largest bank in the world by assets, with 47.5% more than JP Morgan Chase & Co, the largest bank in North America, in 2021.
                            ICBC has held the 1st place in The Banker's Top 1000 World Banks ranking every year since 2012, and it is one of the world's largest and most profitable companies.
                        </Typography>
                        <Typography paragraph>
                            The web page I chose is from the ICBC USA website.
                            It’s the U.S. subsidiary of ICBC, offering financial services and solutions to the Chinese community and students in the U.S.,
                            as well as companies that have business relationships with China.
                        </Typography>
                        <Typography paragraph>
                            Despite its massive size, ICBC's website is poorly designed and makes it difficult for users to easily find the information they need, and understand the key details of a product.
                        </Typography>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            id="part1-2"
                            paragraph
                            gutterBottom
                        >
                            💡 Finding Problems
                        </Typography>
                        <Typography paragraph>
                            You can clearly tell from the screenshot on the right, that this is a poorly designed page.
                        </Typography>
                        <Typography paragraph>
                            There are <strong>different hierarchies</strong> present on the page with different classification methods.
                            Some of the information pages can be reached from multiple entries, while others are hidden deeply and nowhere to be found.
                            Users might find it hard to locate the information they need.
                        </Typography>
                        <Typography paragraph>
                            Besides, the information is presented in <strong>crowded text blocks</strong>, which makes it difficult for users to grasp and the essentials.
                        </Typography>
                        <Typography paragraph>
                            The webpage layout is also <strong>not flexible and responsive</strong>, which makes it unsuitable for the current, mobile-first era.
                        </Typography>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            id="part1-3"
                            paragraph
                            gutterBottom
                        >
                            🦻🏻 Accessibility
                        </Typography>
                        <Typography paragraph>
                            Using the <strong>WAVE Web Accessibility Evaluation Tool</strong>, I found many redundant links, low-contrast links and buttons, as well as empty alternative texts,
                            which make it unfriendly for accessibility tools like screenreaders.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'} alignSelf={'center'}>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/responsive_redesign/original_webpage.png"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part2"
                        >
                            2 - Visual Redesign
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            id="part2-1"
                        >
                            ✍️ Low-Fidelity Wireframing
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            <ul>
                                <li>
                                    I redesigned the navigation bar to make it easier to find the information needed.
                                </li>
                                <li>
                                    The most frequently visited pages are personal banking stuffs and their links are shown on the top of the page.
                                </li>
                                <li>
                                    The less frequently used but still containing important information are relocated to the footer of the page.
                                </li>
                                <li>
                                    I also adopted cards to show different account details. They are clearly organized with a uniformed style and bullet points to highlight the information.
                                </li>
                            </ul>
                        </Typography>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/responsive_redesign/lofi_desktop.png"}
                            style={{padding: 0}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'} alignSelf="end">
                        <Grid container>
                            <Grid item xs={5.5} alignSelf="end">
                                <Container
                                    component="img"
                                    src={process.env.PUBLIC_URL +"./assets/responsive_redesign/lofi_mobile.png"}
                                />
                            </Grid>
                            <Grid item xs={6.5} alignSelf="end">
                                <Container
                                    component="img"
                                    src={process.env.PUBLIC_URL +"./assets/responsive_redesign/lofi_tablet.png"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part2-2"
                        >
                            🪄 Visual Design Style Guide
                        </Typography>
                        <Typography paragraph>
                            These are the main colors, typography, and reusable components used on the page.
                        </Typography>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/responsive_redesign/styleguide.png"}
                            style={{padding: 0}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part2-3"
                        >
                            🛠 High-Fidelity Prototyping
                        </Typography>
                        <Typography paragraph>
                            Based on the low-fi prototype and the visual design style guide, I made these hi-fi prototypes.
                        </Typography>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/responsive_redesign/hifi_desktop.png"}
                            style={{padding: 0}}
                        />
                        <Grid container>
                            <Grid item xs={5} alignSelf="start">
                                <Container
                                    component="img"
                                    src={process.env.PUBLIC_URL +"./assets/responsive_redesign/hifi_mobile.png"}
                                    style={{padding: 0}}
                                />
                            </Grid>
                            <Grid item xs={7} alignSelf="start">
                                <Container
                                    component="img"
                                    src={process.env.PUBLIC_URL +"./assets/responsive_redesign/hifi_tablet.png"}
                                    style={{padding: 0}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h4"
                            fontWeight="bold"
                            id="part3"
                        >
                            3 - Responsive Redesign
                        </Typography>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/responsive_redesign/mockup.png"}
                            style={{padding: 0, objectFit: 'cover', maxHeight: '60vh'}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part3-1"
                        >
                            <Link href={'https://cs1300-webpage.vercel.app/responsive_redesign/responsive_redesign.html#divider'}
                                 
                                  underline="hover"
                                  target="_blank"
                            >🔗 The Redesigned Webpage</Link>
                        </Typography>
                        <Typography paragraph>
                            I used Bootstrap for UI components and responsive layout support (containers, grid, columns).
                            All components and elements uses relative attributes for better responsive layout on arbitrary screen sizes.
                        </Typography>
                        <Typography paragraph>
                            Take a look at the redesigned webpage! You can click the purple subtitle above to access the page.
                            You'll love the new design and improved user experience. Give it a try now and see for yourself!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part3-2"
                        >
                            🔎 Header and Navigation Bar
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            <ul>
                                <li>
                                    Redesigned to highlight the pages that are most likely to be visited by users.
                                </li>
                                <li>
                                    The logo and the navigation bar will automatically adjust their position according to screen width.
                                </li>
                            </ul>
                        </Typography>
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/responsive_redesign/header_tablet.png"}
                            sx={{width: '70%'}}
                        />
                        <Container
                            component="img"
                            src={process.env.PUBLIC_URL +"./assets/responsive_redesign/header_desktop.png"}
                            style={{padding: 0}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className={'left'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part3-3"
                        >
                            🧭 Overview Cards
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            <ul>
                                <li>
                                    Use a 3-column layout on wider screens and single-column layout on narrower screens.
                                </li>
                            </ul>
                        </Typography>
                        <Grid container>
                            <Grid item xs={8.5}>
                                <Container
                                    component="img"
                                    src={process.env.PUBLIC_URL +"./assets/responsive_redesign/overview_desktop.png"}
                                    style={{padding: 0}}
                                />
                            </Grid>
                            <Grid item xs={3.5}>
                                <Container
                                    component="img"
                                    src={process.env.PUBLIC_URL +"./assets/responsive_redesign/overview_mobile.png"}
                                    style={{padding: 0}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} className={'right'}>
                        <Typography
                            component="h3"
                            variant="h5"
                            fontWeight="bold"
                            gutterBottom
                            id="part3-4"
                        >
                            🔦 Detail Cards
                        </Typography>
                        <Typography
                            variant="body1"
                        >
                            <ul>
                                <li>
                                    Responsive column layouts for different screen sizes.
                                </li>
                            </ul>
                        </Typography>
                        <Grid container>
                            <Grid item xs={7.5}>
                                <Container
                                    component="img"
                                    src={process.env.PUBLIC_URL +"./assets/responsive_redesign/detail_desktop.png"}
                                    style={{padding: 0}}
                                />
                            </Grid>
                            <Grid item xs={4.5}>
                                <Container
                                    component="img"
                                    src={process.env.PUBLIC_URL +"./assets/responsive_redesign/detail_mobile.png"}
                                    style={{padding: 0}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    )
}

export default ResponsiveRedesign;